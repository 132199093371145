import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  MultiStoreFailureMapGetResponse,
  MultiStoreOperatingConditionGetResponse,
  MultiStorePowerComparisonGetResponse,
  MultiStorePowerTotalGetResponse,
  CurrentEquipmentSettingsGetResponse,
} from '../interfaces/multistore-service/multistore';
import { CurrentEquipmentOutputStates } from 'src/app/multi-store-management/interfaces/currentEquipmentOutputStates';
import { ComfortableTemperatureSetting } from 'src/app/multi-store-management/interfaces/comfortableTemperatureSetting';

const pathOfMultisite = `multisite/${apiVersion}/`;
const pathOfMultistore = `multistore/${apiVersion}/`;

@Injectable()
export class RestClientMultiStoreService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  多店舗管理アプリAPI
  //  multi-store-management-app API
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 故障MAP取得API
   * response body: MultiStoreFailureMapGetResponse
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  getMultiStoreFailureMapStatus(
    personId: string,
  ): Observable<Response<MultiStoreFailureMapGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}failureMap/v1/failureMap/${personId}`,
    );
  }

  /**
   * 機器OnOffと設定温度現在値取得API
   * response body: CurrentEquipmentSettingsGetResponse
   *
   * @param {string} buildingId 物件ID
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get the current value of the equipment OnOff and set temperature
   * response body: CurrentEquipmentSettingsGetResponse
   * @param {string} buildingId building ID
   * @param {string} equipmentId Equipment ID
   * @return {Observable<Response>} status:HTTP status
   */
  getCurrentEquipmentSettings(
    buildingId: string,
    equipmentId: string,
  ): Observable<Response<CurrentEquipmentSettingsGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}equipmentSettings/v1/buildings/${buildingId}/currentEquipmentSettings/${equipmentId}`,
    );
  }

  /**
   * 機器OnOffと設定温度操作API
   * @param {CurrentEquipmentOutputStates} operations 操作・設定リスト
   * @param {string} equipmentId 機器ID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Equipment OnOff and set temperature operation API
   * @param {CurrentEquipmentOutputStates} operations Operation setting list
   * @param {string} equipmentId Equipment ID
   * @returns {Observable<Response>} status:HTTP status
   */
  postCurrentEquipmentOutputStates(
    operations: CurrentEquipmentOutputStates,
    equipmentId: string,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}equipmentStates/v1/currentEquipmentOutputStates/${equipmentId}`,
      operations,
    );
  }

  /**
   * 快適温度設定更新API
   * @param {ComfortableTemperatureSetting} temperatureSetting 設定温度
   * @param {string} zoneId ゾーンID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Comfortable temperature setting update API
   * @param {ComfortableTemperatureSetting} temperatureSetting Setting temperature
   * @param {string} zoneId Zone ID
   * @returns {Observable<Response>} status:HTTP status
   */
  putComfortableTemperatureSetting(
    temperatureSetting: ComfortableTemperatureSetting,
    zoneId: string,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}comfortableTemperatureSetting/v1/comfortableTemperatureSetting/${zoneId}`,
      temperatureSetting,
    );
  }

  /**
   * 運転状況取得API
   * response body: MultiStoreFailureMapGetResponse
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  getMultiStoreOperatingCondition(
    personId: string,
  ): Observable<Response<MultiStoreOperatingConditionGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}operatingCondition/v1/operatingCondition/${personId}`,
    );
  }

  /**
   * 合計電力消費量取得API
   * response body: MultiStorePowerTotalGetResponse
   *
   * @param {string} personId 人ID
   * @param {string} startTime 計算開始時間
   * @param {string} endTime 計算終了時間
   * @return {Observable<Response>} status:HTTPステータス
   */
  getMultiStorePowerTotal(
    personId: string,
    startTime: string,
    endTime: string,
  ): Observable<Response<MultiStorePowerTotalGetResponse>> {
    const query = `?startTime=${startTime}&endTime=${endTime}`;
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}powerTotal/v1/powerTotal/${personId}${query}`,
    );
  }

  /**
   *  消費電力比較結果取得API
   * response body: MultiStoreFailureMapGetResponse
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  getMultiStorePowerComparison(
    personId: string,
  ): Observable<Response<MultiStorePowerComparisonGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}powerComparison/v1/powerComparison/${personId}`,
    );
  }

  /**
   * NOTE: ここから下は元となったMaruttoの3-15. 多物件遠隔監視サービス のAPI
   *       コピーしたコンポーネントクラスの参照を消すので手間なので残している
   */

  /**
   * マップキー取得API
   * response body: apiKey(string)
   *
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Map key acquisition API
   * response body: apiKey(string)
   *
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiSiteMapKey(): Observable<Response> {
    return this.restClientCommonService.request('get', `${this.endPoint}${pathOfMultisite}mapkey`);
  }

  /**
   * エッジ状態確認API
   * response body: MultiStoreEdgeStatusGetResponse
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge status confirmation API
   * response body: MultiStoreEdgeStatusGetResponse
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiStoreEdgeStatus(buildingId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfMultisite}edgeStates/${buildingId}`,
    );
  }

  /**
   * 機器状態確認API
   * response body: MultiStoreEquipmentStatusGetResponse
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device status confirmation API
   * response body: MultiStoreEquipmentStatusGetResponse
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiStoreEquipmentStatus(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfMultisite}equipmentStates/${edgeId}`,
    );
  }
}
