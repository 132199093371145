<div class="account-panel" #accountPanel>
  <div
    class="account-panel__title"
    tabIndex="0"
    (click)="onToggleMenu()"
    (keydown)="onToggleKeyDown($event)"
  >
    <span class="account-panel__user-icon"></span>
    <span class="account-panel__user-info">
      <p class="account-panel__user-info-name" [title]="userInfo?.name">{{ userInfo?.name }}</p>
      <!-- <p class="account-panel__user-info-role" [title]="userInfo?.roleName">
        {{ userInfo?.roleName }}
      </p> -->
    </span>
    <span
      class="account-panel__triangle-icon"
      [class.account-panel__triangle-icon--expand]="isExpanded"
    ></span>
  </div>
  <ul
    class="account-panel__menu"
    [@expandCollapse]="isExpanded ? 'expand' : 'collapse'"
    role="menu"
  >
    <ng-container *ngFor="let menu of myProfileLinks">
      <li
        *ngIf="menu.isDisplay"
        class="account-panel__item"
        [tabIndex]="isExpanded ? '0' : '-1'"
        (click)="onSelect(menu)"
        (keydown)="onSelectKeyDown($event, menu)"
      >
        <a *ngIf="!menu.isExternalLink" class="account-panel__item-link">
          {{ menu.name | translate }}
        </a>
        <a *ngIf="menu.isExternalLink" class="account-panel__item-link">
          {{ menu.name | translate }}<span *ngIf="menu.isVersionInfo"> {{ version }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
